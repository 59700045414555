<template>
  <v-app-bar
    fixed
    :flat="transparentmenu"
    height="70"
    :color="transparentmenu && !scrolled ? 'transparent' : 'background'"
  >
    <v-container>
      <v-toolbar height="70" flat color="transparent">
        <router-link
          to="/"
          v-if="(!scrolled && transparentmenu) || $vuetify.theme.dark"
          @click="$router.push('/')"
          class="badgetitle"
        >
          <v-img
            height="50px"
            width="50px"
            contain
            src="@/assets/nylogoSVG-white.svg"
          ></v-img>

          <v-img
            v-if="!$vuetify.breakpoint.mobile"
            height="75px"
            width="85px"
            contain
            src="../../assets/logotitle-white.svg"
          >
          </v-img>
        </router-link>
        <router-link
          to="/"
          v-else
          @click="$router.push('/')"
          class="badgetitle"
        >
          <v-img
            height="50px"
            width="50px"
            contain
            src="../../assets/nylogoSVG.svg"
          ></v-img>

          <v-img
            v-if="!$vuetify.breakpoint.mobile"
            height="75px"
            width="85px"
            contain
            src="../../assets/logotitle.svg"
          >
          </v-img>
        </router-link>

        <v-tabs
          class="hidden-sm-and-down"
          :color="transparentmenu && !scrolled ? 'white' : 'primary'"
          :dark="transparentmenu && !scrolled ? true : $vuetify.theme.dark"
          show-arrows
          centered
        >
          <v-tab class="tabs" :to="item.to" v-for="(item, i) in items" :key="i">
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-spacer> </v-spacer>
        <DropdownMenu
          :scrolled="scrolled"
          :transparentmenu="transparentmenu"
          class="hidden-md-and-up"
        />
        <div style="display: flex" class="hidden-sm-and-down">
          <v-icon class="ml-4" color="yellow">mdi-white-balance-sunny</v-icon>
          <v-switch
            class="ml-2"
            color="red darken-3"
            @click="toggleDarkMode"
            :dark="transparentmenu && !scrolled ? true : $vuetify.theme.dark"
            v-model="theme"
            hide-details
          >
          </v-switch>
          <v-icon color="blue">mdi-weather-night</v-icon>
        </div>

        <Avatar
          v-if="$appstate.profile != null"
          class="ml-2 px-2 d-flex justify-center"
          :fullname="$appstate.profile?.navn"
          :radius="20"
          :size="36"
        />
      </v-toolbar>
    </v-container>
  </v-app-bar>
</template>

<script>
import conventusapi from '@/mixins/conventusapi';
import themehandler from '@/mixins/themehandler';
import Avatar from 'vue-avatar-component';
import DropdownMenu from '../blocks/DropdownMenu.vue';
import windowScrollPosition from '@/mixins/scrollhandler';

export default {
  props: {
    transparentmenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [conventusapi, windowScrollPosition('position'), themehandler],
  components: { Avatar, DropdownMenu },
  data() {
    return {
      logindialogue: false,

      items: [
        { name: 'Forside', to: '/' },
        { name: 'Hold oversigt', to: '/holdoversigtjs' },
        { name: 'Klubinfo', to: '/klubinfo' },
        { name: 'Mit Sole', to: '/profil' }
      ]
    };
  },
  computed: {
    scrolled() {
      return this.position[1] > 70;
    }
  }
};
</script>

<style scoped>
.badgetitle {
  display: flex;
  align-items: center;
}
.tabs {
  font-weight: 700;
  font-size: 15px;
}
</style>
